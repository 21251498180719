// Entry point for the build script in your package.json

import "@hotwired/turbo-rails";
import "./controllers";

import posthog from "posthog-js";

posthog.init("phc_XDm2xTZCEfGgMwbLMZGTtSYqae7H5Kd77tDftoYVNOI", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

import jquery from "jquery";
window.jQuery = jquery;
window.$ = jquery;

function initControls() {
  $("#headerwrap").mousemove(function (e) {
    var amountMovedX = -(e.pageX + this.offsetLeft) / 50;
    var amountMovedY = 0;
    $(this).css(
      "background-position",
      amountMovedX + "px " + amountMovedY + "px",
    );
  });

  $(".navbar-collapse").on("click", "a", null, function () {
    if (window.innerWidth < 768) {
      $(".navbar-collapse").collapse("hide");
    }
  });

  $("#search").on("click", function (event) {
    event.preventDefault();
    $("#homelink").fadeOut("normal", function () {
      $(this).remove();
    });
    $("#experiencelink").fadeOut("normal", function () {
      $(this).remove();
    });
    $("#bloglink").fadeOut("normal", function () {
      $(this).remove();
    });
    $("#contactlink").fadeOut("normal", function () {
      $(this).remove();
    });
  });
}

$(document).ready(initControls);
$(document).on("page:load", initControls);
